@import "style/typography.less";

.positive {
  color: rgb(var(--color-success-600));
  .inter-semibold();
}

.negative {
  color: rgb(var(--color-error-600));
  .inter-semibold();
}

.voided {
  color: @grey-300;
  .inter-semibold();
}

.expiryBadge {
  color: rgb(var(--color-error-600));
  .ledgerBadgeCaption {
    color: rgb(var(--color-error-600));
  }
  .ledgerBadgeDate {
    color: rgb(var(--color-error-600));
  }
}

.pendingBadge {
  color: @warning-600;
  .ledgerBadgeCaption {
    color: @warning-600;
  }
  .ledgerBadgeDate {
    color: @warning-600;
  }
}

.voidedBadge {
  color: @warning-600;
  .ledgerBadgeCaption {
    color: @warning-600;
  }
  .ledgerBadgeDate {
    color: @warning-600;
  }
}

.ledgerBadge {
  .ledgerBadgeCaption {
    .inter-bold();
  }

  .ledgerBadgeDate {
    .inter-regular();
  }
}

tr {
  color: rgb(var(--color-grey-800));
}

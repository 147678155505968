@import "style/colors.less";

.creditGrantLoading {
  height: 200px;
}

.creditGrantContainer {
  border: 1px solid rgb(var(--color-grey-100));

  &:last-child {
    margin-bottom: 12px;
  }

  .creditGrantInfo {
    .grantData {
      .grantDataItem {
        .ledgerCaption {
          color: rgb(var(--color-grey-800));
        }

        .green {
          color: rgb(var(--color-success-600)) !important;
        }

        .itemLabel {
          color: rgb(var(--color-grey-800));

          .invoiced {
            color: rgb(var(--color-primary-600));
          }
        }
      }
    }
  }

  .creditLedgerHeader {
    background-color: rgb(var(--color-grey-50));
    border-top: 1px solid rgb(var(--color-grey-100));

    &:hover {
      background-color: rgb(var(--color-primary-50));
    }

    .toggleLedger {
      .toggleLedgerLabel {
        color: rgb(var(--color-grey-600));
      }

      .chevron {
        color: rgb(var(--color-grey-600));
      }
    }

    .ledgerBalance {
      .ledgerCaption {
        color: rgb(var(--color-grey-800));
      }

      .voided {
        color: @warning-600;
      }

      .expired {
        color: rgb(var(--color-error-600));
      }

      .active {
        color: rgb(var(--color-success-600));
      }

      .pending {
        color: rgb(var(--color-grey-600));
      }
    }
  }

  .creditLedgerHeaderOpen {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

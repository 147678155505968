@import "style/colors.less";
@import "style/typography.less";
@import "style/layout.less";

.creditsHeader {
  height: 72px;

  .left {
    .caption {
      color: rgb(var(--color-grey-800));
    }

    .amount {
      color: rgb(var(--color-grey-800));
    }

    .creditType {
      color: rgb(var(--color-grey-800));
    }

    .primaryCreditInfo {
      .amount {
        color: rgb(var(--color-success-600));
      }

      .creditType {
        color: rgb(var(--color-success-600));
      }
    }

    .secondaryCreditInfo {
      .loading {
        height: 26px;
      }
    }
  }

  .right {
    .creditTypeSelectContainer {
      label {
        margin: 0;
      }

      .pricingUnitLabel {
        color: rgb(var(--color-grey-600));
        .inter-regular();
      }

      .creditTypeSelect {
        width: 136px;
      }
    }

    .issueGrant {
      height: 34px;
    }
  }
}

.divider {
  .fullBleed();
  border: 0 none;
  background-color: rgb(var(--color-grey-100));
  height: 1px;
}

.creditGrantsHeader {
  color: rgb(var(--color-grey-800));
}

.shadow {
  box-shadow:
    1px 0 0 0 rgb(var(--color-grey-100)),
    0 1px 0 0 rgb(var(--color-grey-100)),
    1px 1px 0 0 rgb(var(--color-grey-100)),
    1px 0 0 0 rgb(var(--color-grey-100)) inset,
    0 1px 0 0 rgb(var(--color-grey-100)) inset;
}

.toggleLabel {
  color: rgb(var(--color-default-font));
}

.dropdownLabel div {
  color: rgb(var(--color-default-font)) !important;
}
